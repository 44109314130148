@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: Rowdies;
    font-weight: normal;
    src: url("../fonts/Rowdies-Regular.ttf") format("truetype");
  }
  @font-face {
    font-family: Rowdies;
    font-weight: bold;
    src: url("../fonts/Rowdies-Bold.ttf") format("truetype");
  }
  @font-face {
    font-family: Rubik;
    font-weight: normal;
    src: url("../fonts/Rubik-VariableFont_wght.ttf") format("truetype");
  }
  @font-face {
    font-family: Rubik;
    font-weight: italic;
    src: url("../fonts/Rubik-Italic-VariableFont_wght.ttf") format("truetype");
  }

  :root {
    --color-primary: #202028;
    --color-lightPrimary: #21333c;
    --color-secondary: #f5c36e;
    --color-white: #fefee2;
    /* ... */
  }

  h1,
  h2,
  h3,
  h4 {
    @apply text-cg-secondary font-titles;
  }

  .background-animation-radius {
    @apply h-80 w-80 bg-gradient-to-b from-cg-lightPrimary to-cg-primary transition animate-morph overflow-hidden relative flex justify-center items-center;
  }

  .bubbles-variables {
    @apply absolute top-0 left-[var(--random-percent)] h-[var(--size)] w-[var(--size)] bg-gradient-to-b from-cg-lightPrimary to-cg-primary;
  }

  .btn-perso {
    @apply border border-cg-secondary rounded-sm text-cg-secondary my-4 mx-8 overflow-hidden relative transition-all ease-in-out duration-500 py-2 px-6
        before:bg-cg-secondary before:h-96 before:w-cg-35 before:-left-cg-35 before:absolute before:top-0 before:transition-all before:ease-in-out before:duration-500 before:rotate-45
        hover:before:-left-24 hover:text-black;
  }

  .btn-secondary {
    @apply bg-cg-secondary text-cg-primary transition-all ease-in-out duration-500 py-2 px-6 rounded-sm;
  }

  .burger {
    @apply text-cg-secondary border-cg-secondary border-b-2 w-6 block transition-all ease-in-out duration-500
        before:border-cg-secondary before:border-b-2 before:w-full before:block before:mb-2
        after:border-cg-secondary after:border-b-2 after:w-full after:block after:mb-2;
  }

  .burger-open {
    @apply -rotate-45
        after:hidden
        before:rotate-90 before:translate-y-2.5;
  }

  .carousel-indicators > .active {
    @apply bg-cg-secondary
  }
}

@layer utilities {
  .text-stroke {
    -webkit-text-stroke: 0.5px var(--color-secondary);
    text-shadow: 0px 0px 4px var(--color-secondary);
  }
}

.gradient-transparent-to-lightSecondary {
  height: 10rem;
  width: 100%;
  background: linear-gradient(-1deg, #21333c 0%, #21333c 49%, transparent 51%, transparent 100%);
}
